import { Component, Inject } from '@angular/core';
import {
    CookieService,
    IMenu,
    LocalStorageService,
    PERMISSOES_SERVICE_TOKEN,
    PermissoesService
} from '@sbt-suite/components';
import { environment } from 'src/environments/environment';
import { menuRoles } from './core/constants/roles';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    MODE = environment.MODE;
    URL_LOGIN = environment.URL_LOGIN;
    URL_LOGOUT = environment.URL_LOGOUT;

    menuItens: IMenu[] = [
        {
            label: '',
            roles: menuRoles.itensMenu.solicitacaoDeAcesso.roles,
            itens: [
                {
                    label: 'Solicitações de Acesso',
                    icone: 'vpn_key',
                    link: '/solicitacao-acesso',
                    roles: Object.values(
                        menuRoles.itensMenu.solicitacaoDeAcesso.solicitacaoDeAcesso
                    ).flat()
                }
            ]
        }
    ];

    constructor(
        @Inject(PERMISSOES_SERVICE_TOKEN) private _permissoesService: PermissoesService,
        private _cookie: CookieService,
        private _localStorage: LocalStorageService
    ) {
        if (!environment.USE_BACKEND) {
            _localStorage.setItem('appToken', 'teste');
            _permissoesService.registrarPermissoes(['TESTE_VISUALIZAR']);
        }
        _permissoesService.initPermissoes();
    }

    async logout(event: boolean) {
        if (event) {
            try {
                this.clearCookie();
                this._localStorage.clear();
                window.open(this.URL_LOGOUT, '_self');
            } catch (error) {
                console.error(error);
            }
        }
    }

    private clearCookie() {
        let domain = '';
        if (this.MODE == 'dev' || this.MODE == 'prod') {
            domain = 'tvsbt.com.br';
        }
        this._cookie.delete('access_token', '/', domain);
        this._cookie.delete('refreshToken', '/', domain);
    }
}
